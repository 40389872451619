@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Space',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  background-color: hsl(227, 61%, 26%);
  margin: 0;
}

body {
  display: grid;
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    hsl(227, 61%, 13%),
    hsl(227, 61%, 26%),
    hsl(227, 61%, 39%)
  );
}

body::backdrop {
  background-color: hsl(227, 61%, 26%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


progress {
  /* RESETS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;

  width: 300px;
  height: 15px;
  border-radius: 20px;
  background-color: #777;
  color: rgb(20, 240, 221);
}

/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
progress::-webkit-progress-bar {
  background-color: #777;
  border-radius: 20px;
}

progress::-webkit-progress-value { background-image:
     -webkit-linear-gradient(45deg, transparent 40%, rgba(0, 0, 0, .1) 40%, rgba(0, 0, 0, .1) 70%, transparent 70%),
     -webkit-linear-gradient(top, rgba(255, 255, 255, .25), rgba(0, 0, 0, .25)),
     -webkit-linear-gradient(left, #1abc9c, #3498db);

  border-radius: 20px;
}

/* MOZILLA FIREFOX */
progress::-moz-progress-bar {
            background-image:
     -moz-linear-gradient(45deg, transparent 33%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.1) 70%, transparent 70%),
     -moz-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
     -moz-linear-gradient(left, #1abc9c, #3498db);
 
  border-radius: 20px;
}

.MuiSlider-root{

  width: 100px;
}